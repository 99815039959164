<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1>{{ $t("usesCodes") }}</h1>
        <h5 class="text-primary">
          {{ $t("listUsesCodes") }}
        </h5>
      </b-col>
      <b-col
        v-if="isSuperuser"
        style="align-self: center;
      text-align: -webkit-right;
      "
      >
        <b-button
          style="max-width: fit-content;
              "
          variant="primary"
          class="d-flex"
          @click="$refs.modal.show()"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t('createUsesCodes') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card>
          <b-row class="align-items-center">
            <b-col>
              <b-form-input
                v-model="searchUser"
                :placeholder="$t('searchUser')"
                @change="onKeyUp"
              />
            </b-col>
            <b-col>
              <b-form-input
                v-model="searchCode"
                :placeholder="$t('searchCode')"
                @change="onKeyUp"
              />
            </b-col>

            <b-col md="2">
              <b-form-select
                v-model="selectType"
                :options="typesPromo"
                @change="onKeyUp"
              />
            </b-col>
            <b-col md="1">
              <b-button
                variant="primary"
                @click="onKeyUp"
              >
                {{ $t('Search') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-table
                ref="selectableTable"
                :busy="show"
                striped
                hover
                :no-border-collapse="true"
                :selectable="isSuperuser"
                select-mode="single"
                :items="codes"
                :fields="columns"
                responsive
                @row-selected="onRowSelected"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                    <strong>{{ $t('load') }}...</strong>
                  </div>
                </template>
                <template #cell(user)="data">
                  <div class="d-flex align-items-center">
                    {{ cleanUsername(data.item.node.user.username) }}
                  </div>
                </template>
                <template #cell(code)="data">
                  <div class="d-flex align-items-center">
                    {{ data.item.node.promotionalCode.code }}
                  </div>
                </template>
                <template #cell(usedAt)="data">
                  <div class="d-flex align-items-center">
                    {{ formatearFechaHora(data.item.node.usedAt) }}
                  </div>
                </template>
                <template #cell(promotionType)="data">
                  <div class="d-flex align-items-center">
                    {{ typesPromo[data.item.node.promotionType] }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex align-items-center">
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      dropleft
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="17"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click.prevent="onRowSelected([...data.item], 'edit')">
                        <div class="d-flex">
                          <feather-icon
                            icon="Edit2Icon"
                            class=" mr-2"
                            size="18"
                          />
                          {{ $t('dataGeneric.edit') }}
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="isSuperuser"
                        @click.prevent="onRowSelected([...data.item], 'delete')"
                      >
                        <div class="d-flex">
                          <feather-icon
                            icon="Trash2Icon"
                            class=" mr-2"
                            size="18"
                          />
                          {{ $t('dataGeneric.delete') }}
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination
                v-if="rows > perPage"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                size="md"
                @input="fetchData()"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="modal"
      centered
      size="lg"
      :title="$t('usesCodes')"
      @hidden="resetModal"
      @ok="validationForm"
    >
      <validation-observer
        ref="promotionalCode"
        tag="form"
        @submit.stop.prevent="validationForm"
      >
        <b-overlay
          :show="show"
          :opacity="1.00"
          variant="transparent"
          :blur="'2px'"
          rounded="lg"
        >
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('filterAllPromo')"
                label-for="promotionType"
              >
                <b-form-select
                  id="promotionType"
                  v-model="promotionType"
                  :options="typeOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="promotionType == 'SUB'">
              <validation-provider
                v-slot="{ errors }"
                name="subscription"
                rules="required"
              >
                <b-form-group
                  id="subscription"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    v-model="suscriptionName"
                    :placeholder="$t('domain.sus')"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="promotionType == 'CAT'">
              <validation-provider
                v-slot="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-group
                  id="category"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-tags
                    v-model="categories"
                    :placeholder="$t('purchases.category')"
                    :disabled="categories.length == 0"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template
                      #default="{
                        tags,
                        removeTag
                      }"
                    >
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="JSON.parse(tag).id"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="JSON.parse(tag).name"
                            variant="primary"
                            @remove="removeTag(tag)"
                          >
                            {{ JSON.parse(tag).name }}
                          </b-form-tag>
                        </li>
                      </ul>
                    </template>
                  </b-form-tags>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="promotionType == 'CON'">
              <validation-provider
                v-slot="{ errors }"
                name="contents"
                rules="required"
              >
                <b-form-group
                  id="contents"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-tags
                    v-model="contents"
                    :placeholder="$t('contents.content')"
                    :disabled="contents.length == 0"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template
                      #default="{
                        tags,
                        removeTag
                      }"
                    >
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="JSON.parse(tag).id"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="JSON.parse(tag).name"
                            variant="primary"
                            @remove="removeTag(tag)"
                          >
                            {{ JSON.parse(tag).name }}
                          </b-form-tag>
                        </li>
                      </ul>
                    </template>
                  </b-form-tags>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-button
                variant="primary"
                @click="showModalType(promotionType)"
              >
                {{ $t('purchases.selectCategory') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="allowedUsers"
                rules="required"
              >
                <b-form-group
                  id="allowedUsers"
                  :label="$t('User') + ' *'"
                  label-for="allowedUsers"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="userName"
                    v-model="userName"
                    disabled
                    :placeholder="$t('User')"
                    :state="errors.length > 0 ? false : null"
                    maxlength="150"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              class="mt-2"
            >
              <b-button
                variant="primary"
                @click="showModalType('USR')"
              >
                {{ $t('purchases.selectCategory') }}
              </b-button>
            </b-col>
          </b-row> <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="name-input"
                rules="required"
              >
                <b-form-group
                  :label="$t('Código') + ' *'"
                  label-for="name-input"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="name-input"
                    v-model="promotionalCodeName"
                    disabled
                    :placeholder="$t('Código')"
                    :state="errors.length > 0 ? false : null"
                    maxlength="150"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              class="mt-2"
            >
              <b-button
                variant="primary"
                @click="showModalType('PRO')"
              >
                {{ $t('purchases.selectCategory') }}
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-overlay
          :show="show"
          variant="transparent"
          class="d-flex"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <b-spinner
                type="grow"
                variant="dark"
              />
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <span class="sr-only">{{ $t('wait') }}...</span>
            </div>
          </template>
          <b-button
            variant="danger"
            @click="cancel()"
          >
            {{ $t('dataGeneric.cancel') }}
          </b-button>
          <b-button
            variant="success"
            class="ml-1"
            @click="ok()"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
    <b-modal
      ref="SuscriptionModal"
      size="xl"
      hide-footer
      :title="$t('common.el')"
    >
      <div class="d-block text-center">
        <suscription-selector-modal @data="SelectSuscriptionId" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.SuscriptionModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="CatModal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="[
            { key: 'image', label: $t('contents.category') },
            { key: 'name', label: $t('firstName') },
            { key: 'type', label: $t('type') },
          ]"
          @confirm-selection="getCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.CatModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="ConModal"
      size="xl"
      hide-footer
      :title="$t('select')"
    >
      <div class="d-block text-center">
        <content-selector @info="getContent" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.ConModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="userModal"
      size="xl"
      hide-footer
      :title="$t('fileNameUserRegistered')"
    >
      <div class="d-block ">
        <final-user-selector @finalUser="getFinalUser" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs.userModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="promotionalCodesModal"
      size="xl"
      hide-footer
      :title="$t('fileNameUserRegistered')"
    >
      <div class="d-block ">
        <promotional-codes-selector @promotionalCode="getPromotionalCode" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs.promotionalCodesModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BFormGroup,
  BOverlay,
  BFormTag,
  BSpinner,
  BFormTags,
  BCard,
  BFormInput,
  BDropdownItem,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, cleanUsername, showToast,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import FinalUserSelector from '@/views/common/FinalUserSelector.vue'
import SuscriptionSelectorModal from '@/views/common/SuscriptionSelectorModal.vue'
import ContentSelector from '@/views/common/ContentSelector.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'
import promotionalCodesSelector from '@/views/common/promotionalCodesSelector.vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    FinalUserSelector,
    SuscriptionSelectorModal,
    ContentSelector,
    CategorySelector,
    BButton,
    BFormGroup,
    promotionalCodesSelector,
    BSpinner,
    BOverlay,
    BFormTag,
    BFormTags,
    BCard,
    BTable,
    BPagination,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
    BDropdownItem,
  },
  data() {
    return {
      required,
      rows: 0,
      codes: [],
      show: false,
      currentPage: 1,
      userData: getUserData(),
      searchUser: '',
      searchCode: '',
      selectType: 'ALL',
      selectState: null,
      typesPromo: {
        ALL: this.$t('filterAllPromo'),
        SUB: this.$t('domain.sus'),
        CAT: this.$t('purchases.category'),
        CON: this.$t('purchases.content'),
      },
      typesState: [
        { value: null, text: this.$t('subscriptions.status') },
        { value: true, text: this.$t('dataGeneric.active') },
        { value: false, text: this.$t('dataGeneric.inactive') },
      ],
      perPage: 10,
      columns: [
        { key: 'user', label: this.$t('User') },
        { key: 'code', label: this.$t('purchases.code') },
        { key: 'usedAt', label: this.$t('usedAt') },
        { key: 'promotionType', label: this.$t('promotionType') },
      ],
      isSuperuser: false,
      code: null,
      expirationDate: null,
      allowedUses: null,
      promotionType: 'SUB',
      typeOptions: [
        { text: this.$t('domain.sus'), value: 'SUB' },
        { text: this.$t('purchases.category'), value: 'CAT' },
        { text: this.$t('contents.content'), value: 'CON' },
      ],
      headers: {},
      categories: [],
      contents: [],
      allowedUsers: [],
      subscriptionId: null,
      suscriptionName: null,
      promotionalCodeName: null,
      promotionalCodeId: null,
      userId: null,
      userName: null,
      today: new Date(),
      id: null,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.isSuperuser = this.userData.isSuperuser
    if (this.isSuperuser) {
      this.columns = [
        { key: 'user', label: this.$t('User') },
        { key: 'code', label: this.$t('purchases.code') },
        { key: 'usedAt', label: this.$t('usedAt') },
        { key: 'promotionType', label: this.$t('promotionType') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ]
    }
    this.fetchData()
  },
  methods: {
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.promotionalCode.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.id === null) { this.createUsePromotionalCodes() } else this.updatePromotionalCodes()
          }
        })
      })
    },
    onRowSelected(data, action) {
      if (data[0]) {
        this.show = true
        const {
          promotionalCode, id, user, promotionType, categories, contents, subscription,
        } = data[0].node
        const contentsEdges = contents.edges.map(({ node }) => ({ id: node.id, name: node.name }))
        const categoriesEdges = categories.edges.map(({ node }) => ({ id: node.id, name: node.name }))

        this.id = id
        this.promotionType = promotionType
        this.suscriptionId = subscription?.id
        this.suscriptionName = subscription?.name
        this.promotionalCodeId = promotionalCode?.id
        this.promotionalCodeName = promotionalCode?.code
        this.categories = categoriesEdges
        this.contents = contentsEdges
        this.userId = user?.id
        this.userName = user?.username

        if (action === 'edit') {
          this.$refs.modal.show()
        } else if (action === 'delete') {
          const query = `
            mutation {
              deletePromotionalcodeuses(id:${this.id}) {
                found
              }
            }
          `
          axios
            .post('', { query })
            .then(res => {
              if (res.data.data.deletePromotionalcodeuses.found) {
                showToast(this.$t('success'), 1, this)
                this.fetchData()
              }
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
        this.show = false
      }
    },
    getFinalUser(item) {
      const { id, username } = item
      this.userId = id
      this.userName = username
      this.$refs.userModal.hide()
    },
    getPromotionalCode(item) {
      const { id, code } = item
      this.promotionalCodeName = code
      this.promotionalCodeId = id
      this.$refs.promotionalCodesModal.hide()
    },
    resetModal() {
      this.$refs.modal.hide()
      this.fetchData()

      this.id = null
      this.promotionType = 'SUB'
      this.suscriptionId = null
      this.suscriptionName = null
      this.promotionalCodeId = null
      this.promotionalCodeName = null
      this.categories = []
      this.contents = []
      this.userId = null
      this.userName = null

      this.show = false
    },
    createUsePromotionalCodes() {
      this.show = true
      const { headers } = this
      const variables = {
        user: this.userId,
        promotionType: this.promotionType,
        subscription: this.suscriptionId,
        contents: this.contents.map(item => JSON.parse(item).id),
        categories: this.categories.map(item => JSON.parse(item).id),
        promotionalCode: this.promotionalCodeId,
      }
      let qr = ''
      let mt = ''
      switch (this.promotionType) {
        case 'SUB':
          qr = '$subscription: ID'
          mt = 'subscription: $subscription'
          break
        case 'CAT':
          qr = '$categories: [ID]'
          mt = 'categories: $categories'
          break
        case 'CON':
          qr = '$contents: [ID]'
          mt = 'contents: $contents'
          break
        default:
          break
      }
      const query = `
      mutation(
        $promotionType: PromotionalCodeUsesPromotionTypeInput,
       ${qr},$user: ID!, $promotionalCode: ID!      
        ){
          createPromotionalcodeuses(input:{              
              promotionType:$promotionType,
              user:$user,
              promotionalCode:$promotionalCode,
              ${mt}
              }) {
                promotionalCodeUses{
                    id                   
                    promotionType
                      subscription {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }                                     
                }
              }
              
          }`
      axios
        .post('', { query, variables }, { headers })
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)

          this.resetModal()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
    updatePromotionalCodes() {
      this.show = true
      const { headers } = this
      let qr = ''
      let mt = ''
      switch (this.promotionType) {
        case 'SUB':
          qr = '$subscription: ID'
          mt = 'subscription: $subscription'
          break
        case 'CAT':
          qr = '$categories: [ID]'
          mt = 'categories: $categories'
          break
        case 'CON':
          qr = '$contents: [ID]'
          mt = 'contents: $contents'
          break
        default:
          break
      }
      const query = `
        mutation(
        $id: ID!, 
        $promotionType: PromotionalCodeUsesPromotionTypeInput,
       ${qr},$user: ID, $promotionalCode: ID      
        ){
          updatePromotionalcodeuses(id: $id,input:{              
              promotionType:$promotionType,
              user:$user,
              promotionalCode:$promotionalCode,
              ${mt}
              }) {
                promotionalCodeUses{
                    id                   
                    promotionType
                      subscription {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }                                     
                }
              }
          }`
      axios
        .post('', {
          variables: {
            id: this.id,
            user: this.userId,
            promotionType: this.promotionType,
            subscription: this.suscriptionId,
            contents: this.contents.map(item => JSON.parse(item).id),
            categories: this.categories.map(item => JSON.parse(item).id),
            promotionalCode: this.promotionalCodeId,
          },
          query,
        }, { headers })
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)

          this.resetModal()
        })
        .catch(() => {
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },
    cleanUsername,
    onKeyUp() {
      this.currentPage = 1
      this.fetchData()
    },
    formatearFechaHora(fechaISO) {
      if (fechaISO) {
        const fecha = new Date(fechaISO)
        const dia = fecha.getDate().toString().padStart(2, '0')
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
        const anio = fecha.getFullYear()
        const hora = fecha.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })

        return `${dia}/${mes}/${anio} ${hora}`
      }
      return '--/--/---- --:--'
    },
    showModalType(value) {
      switch (value) {
        case 'SUB':
          this.$refs.SuscriptionModal.show()
          break
        case 'CAT':
          this.$refs.CatModal.show()
          break
        case 'CON':
          this.$refs.ConModal.show()
          break
        case 'PRO':
          this.$refs.promotionalCodesModal.show()
          break
        default:
          this.$refs.userModal.show()
          break
      }
    },
    getContent(item) {
      const id = item[0]
      const tag = item[1]
      this.contents.push({ id, name: tag })
      this.$refs.ConModal.hide()
    },
    SelectSuscriptionId(data) {
      const [suscriptionId, suscriptionName] = data
      this.suscriptionId = suscriptionId
      this.suscriptionName = suscriptionName
      this.$refs.SuscriptionModal.hide()
    },
    getCategory(item) {
      const { id, name } = item.node
      this.categories.push({ id, name })
      this.$refs.CatModal.hide()
    },
    fetchData() {
      let clientId = ''
      try {
        // eslint-disable-next-line prefer-destructuring
        clientId = atob(this.userData.profile.client.id).split(':')[1]
        // eslint-disable-next-line no-empty
      } catch (error) { }
      const query = `{
        promotionalcodeUses(
              search_Code:"${this.searchCode}",
              search_User:"${this.searchUser}",
              client_Id:"${clientId}",
              first:${this.perPage},
              ${this.selectType === 'ALL' ? '' : `promotionType:"${this.selectType}",`}
              orderBy:"-created_at",
              offset:${this.perPage * (this.currentPage - 1)},
              ) {
                totalCount
                edges {
                    node {
                      id
                      promotionType
                      createdAt
                      usedAt    
                      subscription {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }    
                      promotionalCode {
                        id
                        code
                      }
                      user {
                        id
                        username
                      }
              }
            }
          }
        }`

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.codes = result.data.data.promotionalcodeUses.edges
          this.rows = result.data.data.promotionalcodeUses.totalCount
        })
        .catch(() => {

        })
    },
  },
}
</script>
